import request from '@/api/request.js'


// 仓库
export function getCangkulist (data) {
  return request({
    url: '/api/wmsWarehouse/page',
    method: 'POST',
    data
  })
}
// 仓库
export function getCangkuAll (data) {
  return request({
    url: '/api/wmsWarehouse/list',
    method: 'POST',
    data
  })
}

// 添加仓库
export function addWms (data) {
  return request({
    url: '/api/wmsWarehouse/add',
    method: 'POST',
    data
  })
}
// 编辑仓库
export function editWms (data) {
  return request({
    url: '/api/wmsWarehouse/edit',
    method: 'POST',
    data
  })
}
// 仓库详情
export function getWmsDetail (data) {
  return request({
    url: '/api/wmsWarehouse/detail',
    method: 'POST',
    data
  })
}

// 删除仓库
export function delWms (data) {
  return request({
    url: '/api/wmsWarehouse/delete',
    method: 'POST',
    data
  })
}
// 删除仓库
export function piliangdelWms (data) {
  return request({
    url: '/api/wmsWarehouse/batchDelete',
    method: 'POST',
    data
  })
}
 