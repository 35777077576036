<template>
  <div>
    <div class="contents">
      <div class="tableSearch">
        <div class="classObj">
          <!-- <el-button @click="addcangku" type="primary" icon="el-icon-plus">新建仓库</el-button> -->
        </div>
        <el-form
          :inline="true"
          ref="searchForm"
          :model="searchForm"
          label-width="100px"
        >
          <el-form-item label="仓库编码" style="margin-bottom: 0px">
            <el-input
              v-model="searchForm.code"
              clearable
              style="width: 190px"
              placeholder="请输入仓库编码"
            ></el-input>
          </el-form-item>
          <el-form-item label="仓库名称" style="margin-bottom: 0px">
            <el-input
              v-model="searchForm.name"
              clearable
              style="width: 190px"
              placeholder="请输入仓库名称"
            ></el-input>
          </el-form-item>
          <el-button @click="getTopSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
        </el-form>
      </div>

      <div class="mainbox">
        <el-table
          :data="tableData"
          border
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
          stripe
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            width="50"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="name" label="仓库名称" width="200"></el-table-column>
          <el-table-column prop="code" label="仓库编码">
          </el-table-column>
          <el-table-column
            prop="owner"
            label="负责人"
            align="center"
            width="100"
          >
          </el-table-column>
          
          <el-table-column
            prop="phone"
            align="center"
            width="150"
            label="联系电话"
          >
          </el-table-column>
           <el-table-column
            prop="createTime"
            align="center"
            width="150"
            label="创建时间"
          >
          </el-table-column>
         
          <!-- <el-table-column label="操作" header-align="center" align="center">
            <template slot-scope="scope">
             <el-button type="text" size="small" @click="editYuncang(scope.row)">编辑</el-button>
             <el-popconfirm title="您确定删除吗？" @confirm="handleDel(scope.row)">
                <el-button style="margin-left:10px" type="text" slot="reference">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column> -->
        </el-table>
        <div class="paginations">
           <!-- <el-button @click="piliangCancal" plain icon="el-icon-remove" style="float:left">批量删除</el-button> -->
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[20, 40, 50, 100]"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
 <addYuncang :isShow="cangFlag" :rowdata="cangRowData"  @cancalfunc="cancalFuns"></addYuncang>
  </div>
</template>

<script>
import { getCangkulist, delWms, piliangdelWms } from "@/api/wms";
import addYuncang from "@/components/addCangku";
let moment = require("moment");
export default {
  name: "wmsDashboard",
  components: { addYuncang },
  data() {
    return {
      searchForm: {
        code: "",
        name: "",
      },
      total: 0,
      page: 1,
      pageSize: 20,
      shopflag: false,      
      shopArr: [],
      loading: false,
      activeName: "first",
      tableData: [],
      cangRowData: [],
      multipleSelection: [],
      cangFlag: false
    };
  },
  methods: {
    addKaituanyiShop() {
      const that = this;
      addKaituanyi(this.kaituanyiAuth).then((res) => {
        if (res.code === 200) {
          that.kaituanyiFlag = false;
          that.kaituanyiAuth.code = "";
          that.handleClose();
          that.$message.success(res.message);
        } else {
          that.$message.error(res.message);
        }
      });
    },
    addQunjielongShop() {
      const that = this
      this.loading = true
      addQunjielong(this.qunjielongAuth).then((res) => {
        this.loading = false
        if (res.code === 200) {
          that.qunjielongFlag = false;
          that.qunjielongAuth.key = "";
          that.handleClose();
          that.$message.success(res.message);
        } else {
          that.$message.error(res.message);
        }
      });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    // addcangku() {
    //   this.$router.push({
    //     path: "/goods/detail"
    //   })
    // },
    addcangku(){
      this.cangFlag = true;
      this.cangRowData = '';
    },
    editYuncang(row){
      console.log(row)
      this.cangFlag = true;
      this.cangRowData = row.id;
    },
    cancalFuns(e){
      if(e){
        this.cangFlag = false
        this.getTopSearch()
      }else{
        this.cangFlag = e
      }
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    getTopSearch(e) {
      var that = this;
      var datas = that.searchForm;
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      getCangkulist(datas).then((res) => {
        console.log(res);
        if (res.code === 200) {
          that.tableData = res.data.rows;
          that.total = res.data.totalRows;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    piliangCancal(){
      var that = this;
      if(that.multipleSelection.length==0){
        that.$message.warning('请选择需要取消的仓库')
        return
      }
      that.$confirm('确定要批量删除仓库吗?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = []
        that.multipleSelection.map((items)=>{
          ids.push(items.id)
        })

        var objs = {}
        objs.ids = ids
        piliangdelWms(objs).then(res => {
          if(res.code ===200){
            that.$message({
              message: '删除成功',
              type: 'success'
            });
            that.getTopSearch() 
          }else{
            that.$message.error(res.message)
          }
        })
        
      })
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    handleDel(row){
      var that = this
      var objs = {}
      objs.id = row.id
      delWms(objs).then(res => {
        if(res.code ===200){
          that.$message({
            message: '删除成功',
            type: 'success'
          });
          that.getTopSearch() 
        }else{
          that.$message.error(res.message)
        }
      })
    },
  },
  mounted() {
    this.getTopSearch();
  },
};
</script>

<style scoped>
.subcon {
  display: block;
  overflow: hidden;
  padding: 10px;
}
.subcon ol {
  float: left;
  width: 90px;
  padding: 5px;
  margin: 0;
  text-align:center;
  cursor: pointer;
}
.subcon .iconfont{
  font-size: 30px;
  line-height: 32px;
  height: 32px;
}
.subcon ol p{
  font-size: 13px;
  display: block;
  color: #333;
  line-height:24px;
}
.subcon ol:hover{
  color: #333;
  font-weight: bold;
  background-color: #fff;
}
.classObj {
  float: right;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}

</style>